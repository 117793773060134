import { Grid } from "@mui/material";

interface ContainerSiteProps{
  padding?: string;
  margin?: string;
  children: any;
}

export default function ContainerSite({padding="0px 0px", margin, children}: ContainerSiteProps){
    return(
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={8}
          className="containerSite"
        >
          {children}
        </Grid>
    )
}