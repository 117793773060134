/* eslint-disable @next/next/no-img-element */
import { MutableRefObject, useState } from "react";
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react";
import Grid from "@mui/material/Grid2";
import { Container, ImageSlide, KeenSlider, KeenSliderThumbnail } from "./styles";

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

function Arrow(props: {
  disabled: boolean;
  left?: boolean;
  onClick: (e: any) => void;
}) {
  const disabeld = props.disabled ? " swiper-button-disabled" : "";

  return (
    <div
      className={
        props.left
          ? `swiper-button-prev ${disabeld}`
          : `swiper-button-next ${disabeld}`
      }
      onClick={props.onClick}
    ></div>
  );
}

export default function GaleriaHotel({ fotos }: any) {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: 4, spacing: 5 },
        },
        "(min-width: 430px)": {
          slides: { perView: 4, spacing: 1 },
        },
        "(min-width: 700px)": {
          slides: { perView: 6, spacing: 1 },
        },
        "(min-width: 1200px)": {
          slides: { perView: 8, spacing: 1 },
        },
        "(min-width: 1536px)": {
          slides: { perView: 8, spacing: 1 },
        },
      },
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );
  return (
    <Grid
      container
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
      size={{
        md: 12,
        sm: 12,
        xs: 12,
      }}
    >
      <Container ref={sliderRef} className="keen-slider">
        {fotos.length > 0 ? (
          <>
            {fotos.map((foto: any, index: number) => {
              return (
                <KeenSlider
                  key={index}
                  className={`keen-slider__slide number-slide${index}`}
                >
                  <ImageSlide
                    src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/${foto}`}
                    alt="Galeria de fotos DPNY"
                  />
                </KeenSlider>
              );
            })}
            {loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                />

                <Arrow
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}
          </>
        ) : (
          ""
        )}
      </Container>
      <Container ref={thumbnailRef} className="keen-slider thumbnail">
        {fotos.length > 0
          ? fotos.map((foto: any, index: number) => {
              const imagem = foto.split("/");
              return (
                <KeenSliderThumbnail
                  key={index}
                  className={`keen-slider__slide number-slide${index}`}
                >
                  <ImageSlide
                    src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/galeria-ohotel/thumb/${imagem[3]}`}
                    alt="Galeria de fotos DPNY"
                  />
                </KeenSliderThumbnail>
              );
            })
          : ""}
      </Container>
    </Grid>
  );
}
